
import {WOW} from '../../../node_modules/wowjs/dist/wow';
import './jquery.nicescroll.min'

// if (window.devicePixelRatio < 2) {
//   if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
//     $('body').niceScroll({
//       mousescrollstep: 40,
//       scrollspeed: 80,
//       zindex: 101,
//       cursorcolor: '#BDBDBD',
//       cursorborder: '1px solid #BDBDBD',
//       cursorwidth: '5px',
//       cursoropacitymax: 0.6,
//       smoothscroll: true,
//       iframeautoresizeL: true,
//     });
//   }
// }
// if (window.devicePixelRatio < 2) {
//   if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
//     setTimeout(() => {
//       $('body').getNiceScroll().resize()
//     }, 500);
//   }
// }

new WOW().init();
$(window).resize(function (){// 缁戝畾鍒扮獥鍙ｇ缉鏀剧殑浜嬩欢
  init();
});
//椤甸潰鍒氬埛鏂版椂璋冪敤
init()
function  init() {
  if ( window.innerWidth>750) {
      var whdef = 100 / 1920;
      var wH = window.innerHeight;// 褰撳墠绐楀彛鐨勯珮搴r
      var wW = window.innerWidth;// 褰撳墠绐楀彛鐨勫搴r
      var rem = wW * whdef;// 浠ラ粯璁ゆ瘮渚嬪€间箻浠ュ綋鍓嶇獥鍙ｅ搴?寰楀埌璇ュ搴︿笅鐨勭浉搴擣ONT-SIZE鍊糪r
      $('html').css('font-size', rem + "px");
  }else {
      let designSize = 750; // 璁捐鍥惧昂瀵竆r
      let html = document.documentElement;
      let wW = html.clientWidth;// 绐楀彛瀹藉害
      let rem = wW * 100 / designSize; 
      document.documentElement.style.fontSize = rem + 'px';
  }  
}