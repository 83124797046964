import './index.scss'

if ( window.innerWidth>768) {
    $(window).scroll(function () {
        // 鍙槸绐楀彛婊氬姩鏉″彂鐢熸粴鍔ㄧ殑鏃跺€ 鍨傜洿鏂瑰悜婊氬姩鍒板灏戣窛绂荤殑鏃跺€ 璁╁ご閮ㄥ鑸潯璁剧疆涓婁竴涓浐瀹氬畾浣嶇殑鏍峰紡
            if ($(window).scrollTop()>=($("header").height())) {
                $("header").addClass("head_scroll");
                $(".head").addClass("head1")
            } else {
                $("header").removeClass("head_scroll");
                $(".head").removeClass("head1")
            }
    })
}else {
    $("header").addClass("head_scroll");
    $(".head").addClass("head1")
}
$(".nav li").hover(function(){
    $(this).find(".li-drop").stop().slideDown();
},function(){
    $(this).find(".li-drop").stop().slideUp();
})
$(".hamburger").click(function(){
    $(this).toggleClass("active");
    $(".mob-nav").toggleClass("active");
})
$(".mob-nav .nLi").click(function() {
    $(this).find(".sub").slideToggle();
    $(this).toggleClass("active");
})